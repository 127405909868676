
import React from 'react'
import PropTypes from 'prop-types';
import "../styles/ResendPurchaseModal.sass"
import Input from '../../input/components/Input';
import Button from '../../button/components/Button';
import ClipLoader from 'react-spinners/ClipLoader'

function ResendPurchaseModal (props) {
  const { 
    isOpen, 
    onCloseModal, 
    onFormFieldChanged, 
    onSubmit,
    loading
  } = props;
    
  return (
    <div 
      className={`resend-purchase-modal 
      ${isOpen ? 'open' : ''}`} 
      onClick={onCloseModal}>
      
      <div 
        className="modal-container" 
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <div>

          <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
            <b>Let's resend your copy of the 📕</b>
            <div>Enter your email that you used to purchase it with.</div>
            <br/>
          </div>

          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <ClipLoader
                color="#7DE77B"
                size={100}
                loading={props.loading}
              />
            </div>
          ) : (
            <div>
              <Input 
                placeholder="Your email" 
                onChange={(e) => onFormFieldChanged(e.target.value)}
              />
              <Button
                text="Resend email"
                onClick={() => onSubmit()}
              />
            </div>
          )}
          
        </div>
      </div>
      
    </div>
  )
}


export default ResendPurchaseModal;

ResendPurchaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onFormFieldChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
}