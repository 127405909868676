
import React, { useState } from 'react'
import logo from '../../../../images/logo.png'
import { Link } from 'gatsby'
import "../styles/NotAuthenticated.sass"
import ResendPurchaseModal from '../../resendPurchase/components/ResendPurchaseModal'
import { paymentService } from '../../../../services'
import { textUtils } from '../../../../utils/textUtils'

const NotAuthenticated = ({ excerpt }) => {

  // const title = headings[0].value;

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [email, setEmail] = useState("");

  const onOpenModal = () => {
    setModalOpen(true);
  }

  const onCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  }

  const onEmailChanged = (val) => {
    setEmail(val);
  }

  const submitResendEmailLink = async () => {
    if (!!email === false) {
      return alert('Howdy, looks like you missed your email!')
    }

    if (textUtils.validateEmail(email) === false) {
      return alert('Looks like your email isnt quite valid. Want to try that again?')
    }

    setLoading(true);

    try {
      await paymentService.resendPurchaseEmail(email);
      alert("Done! If you bought a copy w/ this email, I've sent it. Check your spam and promotions folder. Please make sure to have cookies enabled in order to read the book.")
    } catch (err) {
      console.log(err);
      alert("That didn't work. Want to let me know? Ping me at khalil@khalilstemmler.com");
    }

    setEmail("");
    onCloseModal();
  }
  
  return (
    <div className="not-authenticated-container">
      <main>
        <div className="gradient"></div>
        <div dangerouslySetInnerHTML={{__html: excerpt}}></div>
        <div className="gradient"></div>
      </main>
      <div className="not-authenticated-page">
        <ResendPurchaseModal 
          isOpen={modalOpen}
          onCloseModal={() => onCloseModal()}
          onFormFieldChanged={(val) => onEmailChanged(val)}
          onSubmit={() => submitResendEmailLink()}
          loading={loading}
        />

        <img src={logo}/>
        <p>Howdy 👋</p>
        <p>This is an online wiki and book about the basics 
          of <b>software design and architecture with TypeScript</b> by <a href="https://khalilstemmler.com/">Khalil Stemmler</a>, 
          Developer Advocate @ <a href="https://www.apollographql.com/">Apollo GraphQL</a> .</p>
        <p>This book’s mission is to teach developers the essential skills and practices to 
          write <b>testable</b>, <b>flexible</b>, and <b>maintainable</b> code.</p>
        
        <p>You can read more about the learning journey in the "<a href="https://khalilstemmler.com/articles/software-design-architecture/full-stack-software-design/">Software Design and Architecture Roadmap</a> 🖼️".</p>

        <h2>Already bought it?</h2>
        <p>If you’ve <b>already purchased the book</b>, <span onClick={() => onOpenModal()}>click here</span> to re-send your link. You can read the online wiki or download a copy of the book in PDF, EPUB, and Kindle versions.</p>
      
        <h2>Want access?</h2>
        <p>You can read the <Link to="/Intro-872fd41c47f54a61a318c6729ed7c0bd">intro to the book</Link> for free and visit <a href="https://solidbook.io">solidbook.io</a> to <a href="https://solidbook.io" className="buy-button">buy the book/wiki</a> (it's currently on pre-sale for 33% off)! 
        For recent updates, <Link to="/Updates-6a4d804e388747418a713a4dc09c64e3">click here</Link>. To get an idea of my writing, read some of my best free content <a target="_blank" href="https://khalilstemmler.com/articles/client-side-architecture/introduction/">here</a> and <a target="_blank" href="https://khalilstemmler.com/articles/typescript-domain-driven-design/make-illegal-states-unrepresentable/">here</a>.</p>
      
        <h2>Need help?</h2>
        <p>Something not working? Have a question? You can reach me on <a href="https://twitter.com/stemmlerjs">Twitter</a> or <a href="mailto:khalil@khalilstemmler.com">khalil@khalilstemmler.com</a>.</p>
      </div>
    </div>
  )
}
export default NotAuthenticated;